(function ($, IntlRelativeFormat) {
    var $elements = $('[data-time-format]');

    var formatTime = function (i, element) {
        var $element = $(element);
        var dateTime = parseInt($element.attr('datetime'), 10);
        var locale = $element.attr('data-time-format-locale');

        if (dateTime && locale) {
            var relativeTime = getRelativeTime(locale, dateTime);
            $element.html(relativeTime);
        }
    };

    var getRelativeTime = function (locale, dateTime) {
        var rf = new IntlRelativeFormat(locale);
        var dateValue = new Date(dateTime);

        return rf.format(dateValue);
    };

    $elements.each(formatTime);
})(jQuery, IntlRelativeFormat);
